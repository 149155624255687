import React, { useState } from "react";
import { useRouteParams } from "routes/definition";
import { ViewFmlClaim } from "routes";
import { Tag } from "components/tag/tag";
import fmlStyles from "./fml-claim.module.scss";
import styles from "./styles.module.scss";
import SummaryLabel from "components/summary-label/summary-label";
import { TagForClaimStatus } from "components/tag/tag-for-claim-status";
import clsx from "clsx";
import { LoadingSpinner } from "components/loading-spinner";
import { orderBy } from "lodash-es";
import {
  ClaimDesktopHeaderFragment,
  ClaimMobileHeaderFragment,
  DecisionsSection,
  DesktopClaimIdLabel,
  translateRelationship
} from "./helpers";
import { ClaimDecision, FmlClaimData } from "features/claims/claims-model";
import { Translation } from "components/translation";
import { useApiRequest } from "hooks";
import { useTranslation } from "hooks/translation";
import { StandardPage } from "page-templates/standard";
import { getClaimsService } from "features/claims/claims-service";
import { ClientTranslationProps } from "i18n";
import { ApiErrorView } from "components/api-error/api-error-view";
import { ClaimsTabs } from "./claim-tabs";
import { DocumentUpload } from "../../components/documents/document-upload";
import { DocumentEntityType } from "components/documents/documents";
import { useFormat } from "hooks/format";

const FmlClaimPage: React.FC = () => {
  const params = useRouteParams(ViewFmlClaim);
  const t = useTranslation();

  const [activeTab, setActiveTab] = useState("case_data");

  const caseId = parseInt(params.claimId, 10);

  const { data: fmlClaim, error, isLoading } = useApiRequest(
    async () => getClaimsService().loadFmlClaim(caseId),
    [params.claimId]
  );

  return (
    <StandardPage
      getHeaderFlexItems={({ isDesktop }) =>
        isDesktop ? (
          <ClaimDesktopHeaderFragment />
        ) : (
          <ClaimMobileHeaderFragment caseId={params.claimId} />
        )
      }
      fixedPaneContents={
        fmlClaim && (
          <>
            <div>
              <div className={styles["tag-row"]}>
                <Tag tagStyle="blue">{t({ tag: "claims.tag.fml" })}</Tag>
                {fmlClaim.status && (
                  <TagForClaimStatus
                    status={fmlClaim.status}
                    //locale={fmlClaim.leaveStatusLocale}
                  />
                )}
              </div>
              <h1 className={styles["page-title"]}>{t(fmlClaim.title)}</h1>
            </div>

            <DesktopClaimIdLabel claimId={caseId.toString()} />

            {fmlClaim.absenceType && (
              <SummaryLabel
                label={t({ tag: "claims.label.type_of_leave" })}
                value={t(
                  fmlClaim.claimTypeLocale !== undefined
                    ? fmlClaim.claimTypeLocale
                    : leaveTypeText(fmlClaim.absenceType)
                )}
              />
            )}

            <ClaimsTabs
              tabId={activeTab}
              onChange={tabId => {
                setActiveTab(tabId);
              }}
            ></ClaimsTabs>
          </>
        )
      }
    >
      {isLoading || error ? (
        <>{isLoading ? <LoadingSpinner /> : <ApiErrorView />}</>
      ) : (
        fmlClaim && <FmlClaimContent claim={fmlClaim} tabId={activeTab} />
      )}
    </StandardPage>
  );
};

function formatNumber(number: number): number {
  return Math.round(number * 100) / 100;
}
const FmlClaimContent: React.FC<{ claim: FmlClaimData; tabId: string }> = ({
  claim,
  tabId
}) => {
  const t = useTranslation();
  const { formatLocal } = useFormat();

  const [showAllDecisions, setShowAllDecisions] = useState(false);

  const sortedDecisions: ClaimDecision[] = orderBy(
    claim.decisions,
    ["start"],
    "desc"
  );

  return (
    <>
      {tabId === "case_data" && (
        <div>
          <div>
            {sortedDecisions.length > 0 && (
              <>
                <div className={styles["left-and-right"]}>
                  <h3 className={styles["mini-header"]}>{t({ text: "claims.label.leave_dates" })}</h3>
                </div>

                <DecisionsSection
                  showAll={showAllDecisions}
                  decisions={sortedDecisions}
                  setShowAllDecisions={setShowAllDecisions}
                />
                <hr className={styles["separator"]} />
              </>
            )}
          </div>

          {claim.pertainsTo && (
            <>
              <SummaryLabel
                label={t({ tag: "claims.label.pertains_to" })}
                value={t(translateRelationship(claim.pertainsTo))}
              />
              <hr className={styles["separator"]} />
            </>
          )}

          {claim.policies.length > 0 && (
            <>
              <div className={fmlStyles["claim-hours-description"]}>
                <Translation tag="claims.claim_hours_desc" />
              </div>

              {claim.policies.map(p => (
              <div key={`${p.policyCode}`}>
                <div className={fmlStyles["claim-hours-label"]}>
                  {t(p.policyCodeText)}
                </div>
                <div className={fmlStyles["claim-hours-row"]}>                  
                  <div className={clsx(fmlStyles["hours-block"], fmlStyles["hours-planned"])}>
                    <div className={fmlStyles["hours-planned-wrapper"]}>
                      <div className={fmlStyles["hours-info"]}>
                        <p className={fmlStyles["week-value"]}>{formatNumber(p.planned)}</p>
                        {p.unit && (
                          <p className={fmlStyles["hour-label"]}>
                            {t(getDurationPlannedText(p.unit))}
                          </p>
                        )}
                      </div>
                      {p.unit === "weeks" && p.plannedHours!==null && (
                        <>
                          <span className={fmlStyles["separator"]}></span>
                          <div className={fmlStyles["hours-extra"]}>
                            <p className={fmlStyles["hour-value"]}>{p.plannedHours}</p>
                            <p className={fmlStyles["hour-label"]}>{t({ text: "hours.planned" })}</p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={clsx(fmlStyles["hours-block"], fmlStyles["hours-used"])}>
                    <div className={fmlStyles["hours-used-wrapper"]}>
                      <div className={fmlStyles["hours-info"]}>
                        <p className={fmlStyles["hour-value"]}>{p.used}</p>
                        {p.unit && (
                          <p className={fmlStyles["hour-label"]}>
                            {t(getDurationUsedText(p.unit))}
                          </p>
                        )}
                      </div>
                      {p.unit === "weeks" && p.usedHours!==null && (
                        <>
                          <span className={fmlStyles["separator"]}></span>
                          <div className={fmlStyles["hours-extra"]}>
                            <p className={fmlStyles["hour-value"]}>{p.usedHours}</p>
                            <p className={fmlStyles["hour-label"]}>{t({ text: "hours.used" })}</p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
              <hr className={styles["separator"]} />
            </>
          )}

          {claim.requestDate && (
            <SummaryLabel
              label={t({ tag: "claims.label.date_of_request" })}
              value={formatLocal(claim.requestDate, "MMMM d, yyyy")}
            />
          )}
        </div>
      )}
      {tabId === "documents" && (
        <DocumentUpload
          entityId={claim.caseId}
          entityType={DocumentEntityType.Case}
        />
      )}
    </>
  );
};

function getDurationPlannedText(
  unit: "hours" | "days" | "weeks" | "months"
): ClientTranslationProps {
  switch (unit) {
    case "hours":
      return { tag: "fml_detail.hours_planned" };
    case "days":
      return { tag: "fml_detail.days_planned" };
    case "weeks":
      return { tag: "fml_detail.weeks_planned" };
    case "months":
      return { tag: "fml_detail.months_planned" };
  }
}

function getDurationUsedText(
  unit: "hours" | "days" | "weeks" | "months"
): ClientTranslationProps {
  switch (unit) {
    case "hours":
      return { tag: "fml_detail.hours_used" };
    case "days":
      return { tag: "fml_detail.days_used" };
    case "weeks":
      return { tag: "fml_detail.weeks_used" };
    case "months":
      return { tag: "fml_detail.months_used" };
  }
}

function leaveTypeText(
  absenceType: "continuous" | "intermittent" | "reduced-hours"
): ClientTranslationProps {
  switch (absenceType) {
    case "intermittent":
      return { tag: "claims.intermittent_leave" };
    case "continuous":
      return { tag: "claims.continuous_leave" };
    case "reduced-hours":
      return { tag: "claims.reduced_hours_leave" };
  }
}

export default FmlClaimPage;
